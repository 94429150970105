<template>
  <div>TagsEdit</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'TagsEdit',
  data() {
    return {}
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Edit new Tag', route: '' }])
  },
}
</script>

<style lang="scss"></style>
